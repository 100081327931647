<script>

import Header from '../components/Header.vue';
import Carrousel from '../components/home/Carousel.vue';
import Categories from '../components/home/Categories.vue';
import Partner from '../components/home/Partner.vue';
import About from '../components/home/About.vue';
import Clients from '../components/home/Clients.vue';
import Footer from '../components/Footer.vue';

/**
 * Dashboard component
 */
export default {
  components: {
   
    Header,
    Carrousel,
    Categories,
    Partner,
    About,
    Clients,
    Footer
    
   
  },
  
};
</script>

<template>
<div>
  <Header />
  <Carrousel />  
  <Categories />
  <Partner />
  <About />
  <Clients />
   <br><br><br><br><br><br><br><br><br><br><br>
  <Footer />
  
</div>  
  </template>