<template>

 <div class="container" style="margin-top:50px;">
 <div>  
    <h1><strong> Among Our Clients</strong></h1>
   
<br><br>
 </div>
        <carousel :autoplay="true" :nav="false" :items= 5>
 

<div style="width:140px;height:100px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/sagemcom.jpg">
</div>
<div style="width:150px;height:75px;margin-top:20px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/lacroix.png">
</div>
<div style="width:200px;height:100px;margin-top:40px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/asteel.png">
</div>
<div style="width:200px;height:80px;margin-top:15px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/tis.jpg">
</div>
<div style="width:200px;height:80px;margin-top:25px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/eleontec.jpg">
</div>
<div style="width:200px;height:70px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/marquard.jpg">
</div>
<div style="width:160px;height:100px;margin-top:30px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/leoni.png">
</div>
<div style="width:140px;height:60px;margin-top:10px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/ibl.png">
</div>
<div style="width:200px;height:100px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/sacema.jpg">
</div>
<div style="width:200px;height:100px;margin-top:10px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Clients/latecoire.jpg">
</div>


</carousel>
</div>
</template>
<script>

import carousel from 'vue-owl-carousel2'

export default {
    components: { carousel },
}

</script>