<template>

 <div class="container" style="margin-top:50px;">
 <div>  
    <h1><strong> Our Partners</strong></h1>
    <h3>
We carefully scrutinize and investigate any product or company before agreeing to represent them.</h3>
<br><br>
 </div>
        <carousel :autoplay="true" :nav="false" :items= 5>
 

<div style="width:180px;height:100px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/ersa.png">
</div>
<div style="width:150px;height:75px;margin-right:10px;margin-left:10px;margin-top:10px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/DCT-logo-150x150.png">
</div>
<div style="width:200px;height:100px;margin-top:22px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/interflux.png">
</div>
<div style="width:200px;height:80px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/metcal.jpg">
</div>
<div style="width:200px;height:80px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/logo-Osai-RGB.png">
</div>
<div style="width:200px;height:70px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/dediprog.png">
</div>
<div style="width:200px;height:100px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/fiam.png">
</div>
<div style="width:200px;height:60px;">
    <img style="max-height:100%; max-width:100%;display:block;
    margin:auto;" 
         src="../../assets/images/Partener/logo-OLAMEF.png">
</div>


</carousel>
</div>
</template>
<script>

import carousel from 'vue-owl-carousel2'

export default {
    components: { carousel },
}

</script>