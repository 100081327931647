<template>
  <div>
    <div class="">
      <div class="">
        <div>
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#eae9e5"
            style="text-shadow: 1px 1px 2px #333 ;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <div class="carousel-item" active>
              <div class="parent d-flex justify-content-center">
                <img
                  class="d-block img-fluid"
                  src="../../assets/images/Carousel/reflow.png"
                  alt="category.name"
                />
              </div>
            </div>
             <div class="carousel-item" >
              <div class="parent d-flex justify-content-center">
                <img
                  class="d-block img-fluid"
                  src="../../assets/images/Carousel/soldering.png"
                  alt="category.name"
                />
              </div>
            </div>
             <div class="carousel-item" >
              <div class="parent d-flex justify-content-center">
                <img
                  class="d-block img-fluid"
                  src="../../assets/images/Carousel/3xi-m110_1.png"
                  alt="category.name"
                />
              </div>
            </div>
             <div class="carousel-item" >
              <div class="parent d-flex justify-content-center">
                <img
                  class="d-block img-fluid"
                  src="../../assets/images/Carousel/Desoldering_Soldering_Systems.png"
                  alt="category.name"
                />
              </div>
            </div>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
     onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
},
}


</script>